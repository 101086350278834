import React from "react"
import { graphql } from "gatsby"
import Head from "../components/head"
import Bio from "../components/bio.jsx"
import SEO from "../pages/SEO.jsx"
import "../styles/index.scss"
import prof from "../assets/prof.jpg"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        meta
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
      }
      html
    }
  }
`

function Post(props) {
  console.log(JSON.stringify(props.data.markdownRemark.frontmatter.meta))
  return (
    <div className="postpage">
      <script async defer data-domain="sameerkapur.com" src="https://plausible.io/js/plausible.js"></script>
      <SEO
        title={props.data.markdownRemark.frontmatter.title}
        description={
          props.data.markdownRemark.frontmatter.description ||
          props.data.markdownRemark.frontmatter.excerpt ||
          "Sameer's personal website."
        }
      />
      <div className="posttop">
        <br>
        </br>
        <div>
        <a href="/">
        <img src={prof} alt="profile" className="prof-pic-bio" />
        </a>
      </div>
        <p>
          Learnings by <a href="/">Sameer Kapur</a>
        </p>
        <Head title={props.data.markdownRemark.frontmatter.title} />
        <h1> {props.data.markdownRemark.frontmatter.title}</h1>
        <p className="italic">
          Published {props.data.markdownRemark.frontmatter.date}
        </p>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
      ></div>
      <br></br>
      <hr className="bio" />
      <Bio />
      <br></br>
      {/* <hr className="bio" /> */}
    </div>
  )
}

export default Post

import React from "react"
import prof from "../assets/prof.jpg"

function Bio() {
  return (
    <div className="bio-container">
      <div>
        <a href="/">
        <img src={prof} alt="profile" className="prof-pic-bio" />
        </a>
      </div>
      <div>
        <h3>That's all, folks!</h3>
        <p>
          Heya! this was written by me, Sameer! I love talking about product growth, 
          the latest startups and many other things that won’t fit in this sentence. 
          I try to write about what I've learned or done every
          once in a while. Follow me on&nbsp;
          <a href="https://twitter.com/sameerkapur">Twitter</a> for more
          frequent updates.
        </p>
      </div>
    </div>
  )
}

export default Bio
